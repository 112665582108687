import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Contents from "../components/Contents";
import { M_BREAKPOINT } from "../styles";
import Breadcrumb from "../components/Breadcrumb";
import MdxRenderer, { MDXQuery } from "../components/mdx";

const PostContainer = styled.main`
  height: 100%;
  width: 100%;
  padding-right: var(--sGutter);
  max-width: 1200px;

  @media (max-width: ${M_BREAKPOINT}px) {
    padding-right: 0;
  }
`;

const Container = styled.section`
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 var(--sGutter);
  height: 100%;
  width: 100%;

  // Breadcrumb
  & > :nth-child(1) {
    margin-bottom: var(--l);
    grid-area: 1 / 1 / 2 / 6;

    @media (max-width: ${M_BREAKPOINT}px) {
      grid-area: 1 / 1 / 2 / 6;
      margin-top: var(--l);
    }
  }
  // PostContainer
  & > :nth-child(2) {
    //
    grid-area: 2 / 1 / 3 / 5;

    @media (max-width: ${M_BREAKPOINT}px) {
      grid-area: 3 / 1 / 4 / 6;
    }
  }
  // Contents
  & > :nth-child(3) {
    grid-area: 2 / 5 / 3 / 6;

    @media (max-width: ${M_BREAKPOINT}px) {
      grid-area: 2 / 1 / 3 / 6;
    }
  }
`;

interface DocumentProps {
  data: {
    mdx: MDXQuery;
  };
}

const Document = ({ data: { mdx } }: DocumentProps) => {
  return (
    <Container>
      <Helmet>
        <title>{mdx.frontmatter.title}</title>
      </Helmet>
      <Breadcrumb />
      <PostContainer id="content">
        <MdxRenderer mdx={mdx} />
      </PostContainer>
      <Contents contents={mdx.tableOfContents} />
    </Container>
  );
};

export default Document;

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
      tableOfContents(maxDepth: 3)
    }
  }
`;
