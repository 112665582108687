import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Link } from "gatsby";
import styled from "styled-components";

import { H1, H2, H3, H4, OL, UL, P, A } from "./Elements";

import { MDXQuery } from "./index";

const shortcodes = {
  Link,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  ol: OL,
  ul: UL,
  p: P,
  a: A,
};

const Container = styled.section`
  * {
    color: var(--textColor);
  }
`;

interface MdxRendererProps {
  mdx: MDXQuery;
}

const MdxRenderer = ({ mdx }: MdxRendererProps) => {
  return (
    <Container>
      <H1>{mdx.frontmatter.title}</H1>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Container>
  );
};

export default MdxRenderer;
