import React, { useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { ChevronRight } from "react-feather";

import { hrefStyles } from "../styles";

const Container = styled.nav`
  margin-top: var(--l);
`;

const Paths = styled.ol`
  display: flex;
`;

const Path = styled.li`
  font-weight: var(--bold);

  & > a {
    display: flex;
    align-items: center;
    color: inherit;
    text-transform: capitalize;

    & > p {
      ${hrefStyles}
    }

    & > svg {
      margin: 0 var(--s);
    }
  }
`;

interface Path {
  path: string;
  uri: string;
}

const Breadcrumb = () => {
  const props = useLocation();
  const { pathname } = props;

  const paths = useMemo(() => {
    const splitName = pathname.split("/");
    const splitPath = splitName.filter((path) => path !== "");

    return splitPath.reduce((ctx, path) => {
      const prevPath = ctx[ctx.length - 1]?.uri;
      const readablePath = path.replace(/-/g, " ");

      ctx.push({
        path: readablePath,
        uri: prevPath ? `${prevPath}/${path}` : `/${path}`,
      });

      return ctx;
    }, [] as Path[]);
  }, [pathname]);

  return (
    <Container aria-label="Breadcrumb">
      <Paths>
        {paths.map(({ path, uri }, i) => (
          <Path key={i}>
            <Link to={uri}>
              <p>{path}</p>
              {i !== paths.length - 1 && <ChevronRight size={16} />}
            </Link>
          </Path>
        ))}
      </Paths>
    </Container>
  );
};

export default Breadcrumb;
