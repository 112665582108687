import React, { useState } from "react";
import { ChevronDown, ChevronRight } from "react-feather";
import styled from "styled-components";

import { hrefStyles, ML_BREAKPOINT, M_BREAKPOINT } from "../styles";

import { DOCS_HEADER_HEIGHT } from "./Header/Header";
import { MDXQuery } from "./mdx";

const Container = styled.aside`
  padding: var(--l) 0;
  position: relative;

  @media (max-width: ${ML_BREAKPOINT}px) {
    padding: 0;
  }
`;

const ContentContainer = styled.div`
  position: sticky;
  top: calc(${DOCS_HEADER_HEIGHT} + var(--l));
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: var(--sText);
  font-weight: var(--xBold);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  margin-bottom: var(--xl);
  color: var(--textColor);
  width: max-content;
  line-height: 1.2rem;
  display: flex;
  align-items: center;

  @media (max-width: ${M_BREAKPOINT}px) {
    margin-bottom: var(--m);
    cursor: pointer;
  }
`;

const NavItems = styled.ul<{ open: boolean }>`
  @media (max-width: ${M_BREAKPOINT}px) {
    display: ${({ open }) => (open ? "block" : "none")};
  }
`;

const Heading = styled.a`
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--lightTextColor);
  font-size: var(--sText);
  line-height: 1.25rem;

  & > span {
    ${hrefStyles}
  }
`;

const Icon = styled.div`
  @media (min-width: ${M_BREAKPOINT}px) {
    display: none;
  }
`;

const SubItems = styled.ul`
  display: flex;
  flex-direction: column;
`;

interface ContentsProps {
  contents: MDXQuery["tableOfContents"];
}

const Contents = ({ contents: { items } }: ContentsProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <ContentContainer>
        {items?.length > 0 && (
          <Title
            role="button"
            aria-label="toggle table of contents"
            onClick={() => setOpen(!open)}
          >
            Table of Contents{" "}
            <Icon>
              {open ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
            </Icon>
          </Title>
        )}
        <Nav>
          <NavItems open={open}>
            {items?.map((item, i) => (
              <li key={i}>
                <Heading key={i} href={item.url}>
                  <span>{item.title}</span>
                </Heading>
                {item.items && item.items?.length > 0 && (
                  <SubItems>
                    {item.items?.map((subitem, j) => (
                      <Heading
                        style={{ marginLeft: "var(--l)" }}
                        key={j}
                        href={subitem.url}
                      >
                        <span>{subitem.title}</span>
                      </Heading>
                    ))}
                  </SubItems>
                )}
              </li>
            ))}
          </NavItems>
        </Nav>
      </ContentContainer>
    </Container>
  );
};

export default Contents;
